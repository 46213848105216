<template>
  <div>
    <SectionHero v-bind="content.hero" />

    <SectionVideosFeature v-bind="content.feature" />

    <SectionNewIn v-bind="content.new" />

    <SectionVideosCharacters />

    <SectionFilter :title="content.filter.title"
                   :character="selectedCharacter"
                   :categories="categories"
                   :type="selectedType"
    >
      <div class="mt-[8vw] md:mt-[4vw] grid md:grid-cols-3 md:gap-y-[4vw] gap-y-[10vw] md:gap-x-[2vw]">
        <CardEpisode v-for="(item, index) in playlists.slice(0, toShow)"
                     :key="index"
                     v-bind="item"
        />
      </div>

      <div class="flex"
           v-if="playlists.length > toShow"
      >
        <ButtonLefty @click="showMore()"
                     background-color="white"
                     color="indigo"
                     class="mx-auto mt-[12vw] md:mt-[4vw]"
                     icon="ArrowDown"
        >
          {{ content.filter.cta }}
        </ButtonLefty>
      </div>
    </SectionFilter>

    <Container class="mb-[16vw] md:mb-[5vw] space-y-[8vw] md:space-y-[5vw] mt-[16vw] md:mt-[10vw]">
      <CardBig v-for="(card, index) in content.bigCards"
               :key="index"
               v-bind="card"
      />
    </Container>

    <CardPromo class="mb-[20vw] md:mb-[10vw] bg-white"
               v-bind="content.promo"
    />
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, queryContent, useNuxtApp } from '#imports';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';

type TemplateVideosProps = {
    content: any
}
const { $event } = useNuxtApp();
defineProps<TemplateVideosProps>();

const defaultToShow = 6;
const loadMore = 6;
const toShow = ref(defaultToShow);
const route = useRoute();
const router = useRouter();
const selectedCharacter = ref(route.query.character ? route.query.character as string :'all');
const selectedType = ref(route.query.type ? route.query.type as string : 'all');

const { data } = await useAsyncData('playlists', () => queryContent('playlists').sort({ order: 1, $numeric: 1 }).find());

const categories = [
  {
    value: 'all',
    label: 'All Shows'
  },
  {
    value: 'playlists',
    label: 'Playlists'
  },
  {
    value: 'gameplays',
    label: 'Gameplays'
  }
];

watch(
  () => route.query.character,
  character => {
    selectedCharacter.value = character ? character as string :'all' ;

    router.push({
      query: {
        ...route.query,
        type: undefined
      }
    });
  }
);

watch(
  () => route.query.type,
  type => {
    selectedType.value = type ? type as string : 'all';
  }
);

watch(
  [
    () => route.query.type,
    () => route.query.character
  ],
  () => {
    toShow.value = defaultToShow;
  }
);

function showMore(){
  $event('show-more', { props: { title: 'videos' } });
  toShow.value = toShow.value + loadMore;
}

const playlists = computed(() => {
  if (data && data.value && data.value.length > 0) {

    let items = data.value;

    if (selectedCharacter.value && selectedCharacter.value !== 'all') {
      items = items.filter(item => item.characters.includes(selectedCharacter.value));
    }

    if (selectedType.value && selectedType.value !== 'all') {
      items = items.filter(item => item.category === selectedType.value);
    }

    return items;
  }

  return [];
});
</script>